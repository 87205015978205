button.mat-focus-indicator.mat-mini-fab.mat-button-base.mat-accent {
    background: #fff;
    color: #33475b;
}

button.mat-focus-indicator.btn-no-border.mat-mini-fab.mat-button-base.mat-accent {
    background: none;
    box-shadow: none;
    font-size: 10px;
}

.mat-paginator .mat-paginator-container {
    justify-content: end !important;
}

body.light .mat-form-field-appearance-standard .mat-form-field-underline,
body .light .mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: rgb(152 152 152 / 42%);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(82, 195, 200, 0.3) !important;
}

.mat-badge-content {
    color: #fff !important;
}

.card-link mat-icon {
    font-size: 16px;
    line-height: 30px;
}

.mat-icon-button {
    border: none !important;
}

.mat-dialog-container {
    border-radius: 4px !important;
}

mat-card-content.mat-card-content.card-content {
    margin-top: 20px;
}
mat-form-field.mat-form-field.ng-tns-c66-31.mat-primary.mat-form-field-type-mat-input.mat-form-field-appearance-fill
    .mat-form-field-can-float.mat-form-field-has-label.mat-form-field-hide-placeholder.ng-untouched.ng-pristine.ng-valid {
    margin-right: 15px;
}

mat-card.mat-card.mat-focus-indicator.ch-source {
    padding: 15px 7px;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgb(246, 246, 246) !important;
}

.mat-horizontal-stepper-header {
    margin: 0 20px !important;
    padding: 3px !important;
    height: auto !important;
}

.mat-horizontal-stepper-header-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: sticky;
    background-color: white !important;
    top: 0;
    z-index: 3;
    padding: 10px 0;
}

.mat-step-icon-selected {
    background-color: #1191c1 !important;
}

.mat-step-label {
    min-width: 0 !important;
}

.mat-step-icon {
    margin: 0 !important;
    height: 60px !important;
    width: 60px !important;
}

.mat-step-icon.mat-step-icon-state-edit {
    background-color: #52c3c8 !important;
}

.mat-step-icon.mat-step-icon-selected {
    background-color: #1191c1 !important;
}

.mat-step-icon {
    background-color: #99acc2 !important;
    border: 7px solid #fff !important;
    box-shadow: 0 0 0 1.5px rgb(153 162 194) !important;
}

.mat-horizontal-content-container {
    padding: 24px !important;
}

.mat-step-icon .mat-icon {
    font-size: 25px !important;
    height: 25px !important;
    width: 25px !important;
}

mat-step-header {
    flex-direction: column;
}

.video-wizard > .mat-horizontal-stepper-header-container {
    display: none !important;
}

.mat-step-text-label {
    margin-top: 5px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    border: solid 1px $primary-color !important;
}

.mat-button-toggle-checked {
    background-color: $primary-color !important;
    color: white !important;
}

.mat-button-toggle {
    color: $primary-color !important;
}

body.light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary,
body .light .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    color: white;
}

body.light
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary
    .mat-chip-remove,
body
    .light
    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary
    .mat-chip-remove {
    color: white;
}

body.light .mat-divider,
body .light .mat-divider {
    border-top-color: #e2e8f0;
    // margin: 1.3rem 0;
}
