$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1600px;

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet-s {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet-l {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
