.action-toolbar {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #dedede !important;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 15px 30px;
    box-shadow: 0 1px 2px #2d3e501f !important;
    z-index: 9 !important;
    background-color: #F1F5F9;
}

.sticky-menu {
    position: sticky;
    top: 0;
    background: #ffffff !important;
    z-index: 999;
}

.post-card-feature {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    align-items: center;
}

.post-card-wrapper {
    max-width: 290px;
    flex-wrap: wrap;
    flex: 0 0 391px;
    padding: 0 8px;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.post-card {
    height: 500px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    color: #33475b !important;
    border: 1px #99acc2 !important;
}

.card-info-title {
    font-size: 14px;
    font-weight: bold;
}

.post-card img {
    background-position: center;
    background-size: cover;
    object-fit: cover;
    // height: 15vw;
}

.post-card .checkbox {
    position: absolute;
    top: 20px;
}

.company-info p {
    margin-top: 20px;
}

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-tag-button {
    display: flex;
    justify-content: flex-end;
}

.action-buttons {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 160px;
    right: 0;
    width: 25%;
}

.card-link {
    display: flex;
}

.card-link p {
    color: #99acc2 !important;
}

.card-content a {
    color: #008abd !important;
}

.primary-color-button {
    background: #52c3c8 !important;
    color: #ffffff !important;
}

.primary-color-button.mat-button-disabled {
    background: #eaf0f6 !important;
}

.post-card mat-card-title {
    font-size: 20px;
    font-weight: bold;
}

.no-result {
    margin: 2em 1.5em;
}

.ch-filter-modal {
    width: 100%;
    position: absolute;
    z-index: 1024;
    background: #fff !important;
    top: 88px;
    left: 0;
    padding: 30px;
    border: 2px solid #dedede !important;
}

.close-filter {
    display: inline-block;
    position: absolute;
    right: 40px;
    color: #dc3545 !important;
    cursor: pointer;
}

.display-none {
    display: none;
}

.sticky-menu > form > mat-form-field > .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.action-bar-button-margin {
    margin: 4px 0 !important;
}

@media screen and (width: 1280px) {
    .post-card-wrapper {
        max-width: 250px;
        flex-wrap: wrap;
        flex: 0 0 391px;
        padding: 0 15px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .card-actions button {
        margin-right: 10px;
    }
}
