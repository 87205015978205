@import "helpers/mixins";

.flex {
  display: flex;
  // flex-wrap: wrap;

  &.align-items-center {
    align-items: center;
  }

  // &.margin-align {
  //   // margin: 0 -15px;
  // }

  &.justify-content-sb {
    justify-content: space-between;
  }
}

.f-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  flex-direction: column;

  &.w {

    &-5 {
      width: 5%;
      max-width: 5%;
      flex: 0 0 5%;
    }

    &-10 {
      width: 10%;
      max-width: 10%;
      flex: 0 0 10%;
    }

    &-20 {
      width: 20%;
      max-width: 20%;
      flex: 0 0 20%;
    }

    &-25 {
      width: 25%;
      max-width: 25%;
      flex: 0 0 25%;
    }

    &-30 {
      width: 30%;
      max-width: 30%;
      flex: 0 0 30%;
    }

    &-33 {
      width: 33.333%;
      max-width: 33.333%;
      flex: 0 0 33.333%;
    }

    &-40 {
      width: 40%;
      max-width: 40%;
      flex: 0 0 40%;
    }

    &-50 {
      width: 50%;
      max-width: 50%;
      flex: 0 0 50%;
    }

    &-60 {
      width: 60%;
      max-width: 60%;
      flex: 0 0 60%;
    }
    &-70 {
      width: 70%;
      max-width: 70%;
      flex: 0 0 70%;
    }
    &-80 {
      width: 80%;
      max-width: 80%;
      flex: 0 0 80%;
    }

    &-90 {
      width: 90%;
      max-width: 90%;
      flex: 0 0 90%;
    }

    &-100 {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }

    @include mobile {

      &-m-20 {
        width: 20%;
        max-width: 20%;
        flex: 0 0 20%;
      }

      &-m-25 {
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
      }

      &-m-30 {
        width: 30%;
        max-width: 30%;
        flex: 0 0 30%;
      }

      &-m-33 {
        width: 33.333%;
        max-width: 33.333%;
        flex: 0 0 33.333%;
      }

      &-m-40 {
        width: 40%;
        max-width: 40%;
        flex: 0 0 40%;
      }

      &-m-50 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
      }

      &-m-60 {
        width: 60%;
        max-width: 60%;
        flex: 0 0 60%;
      }
      &-m-70 {
        width: 70%;
        max-width: 70%;
        flex: 0 0 70%;
      }
      &-m-80 {
        width: 80%;
        max-width: 80%;
        flex: 0 0 80%;
      }

      &-m-90 {
        width: 90%;
        max-width: 90%;
        flex: 0 0 90%;
      }

      &-m-100 {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }

    @include tablet-s {
      &-ts-20 {
        width: 20%;
        max-width: 20%;
        flex: 0 0 20%;
      }

      &-ts-25 {
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
      }

      &-ts-30 {
        width: 30%;
        max-width: 30%;
        flex: 0 0 30%;
      }

      &-ts-33 {
        width: 33.333%;
        max-width: 33.333%;
        flex: 0 0 33.333%;
      }

      &-ts-40 {
        width: 40%;
        max-width: 40%;
        flex: 0 0 40%;
      }

      &-ts-50 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
      }

      &-ts-60 {
        width: 60%;
        max-width: 60%;
        flex: 0 0 60%;
      }
      &-ts-70 {
        width: 70%;
        max-width: 70%;
        flex: 0 0 70%;
      }
      &-ts-80 {
        width: 80%;
        max-width: 80%;
        flex: 0 0 80%;
      }

      &-ts-90 {
        width: 90%;
        max-width: 90%;
        flex: 0 0 90%;
      }

      &-ts-100 {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }

    @include tablet-l {
      &-tl-20 {
        width: 20%;
        max-width: 20%;
        flex: 0 0 20%;
      }

      &-tl-25 {
        width: 25%;
        max-width: 25%;
        flex: 0 0 25%;
      }

      &-tl-30 {
        width: 30%;
        max-width: 30%;
        flex: 0 0 30%;
      }

      &-tl-33 {
        width: 33.333%;
        max-width: 33.333%;
        flex: 0 0 33.333%;
      }

      &-tl-40 {
        width: 40%;
        max-width: 40%;
        flex: 0 0 40%;
      }

      &-tl-50 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
      }

      &-tl-60 {
        width: 60%;
        max-width: 60%;
        flex: 0 0 60%;
      }
      &-tl-70 {
        width: 70%;
        max-width: 70%;
        flex: 0 0 70%;
      }
      &-tl-80 {
        width: 80%;
        max-width: 80%;
        flex: 0 0 80%;
      }

      &-tl-90 {
        width: 90%;
        max-width: 90%;
        flex: 0 0 90%;
      }

      &-tl-100 {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
      }
    }
  }


}
