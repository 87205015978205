@import 'helpers/varibles';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-prime: (
  50: $primary-color,
  100: $primary-color,
  200: $primary-color,
  300: $primary-color,
  400: $primary-color,
  500: $primary-color,
  600: $primary-color,
  700: $primary-color,
  800: $primary-color,
  900: $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$theme-second: (
  50: $link-color,
  100: $link-color,
  200: $link-color,
  300: $link-color,
  400: $link-color,
  500: $link-color,
  600: $link-color,
  700: $link-color,
  800: $link-color,
  900: $link-color,
  A100: $link-color,
  A200: $link-color,
  A400: $link-color,
  A700: $link-color,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$sociuu-frontend-prime: mat-palette($theme-prime);
$sociuu-frontend-accent: mat-palette($theme-second, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sociuu-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sociuu-frontend-theme: mat-light-theme(
  (
    color: (
      primary: $sociuu-frontend-prime,
      accent: $sociuu-frontend-accent,
      warn: $sociuu-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($sociuu-frontend-theme);

/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: #33475b !important;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 36px !important;
  color: #33475b !important;
}
h2 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 32px !important;
  color: #33475b !important;
}
h3 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  color: #33475b !important;
}

h4 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #33475b !important;
}

h5 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #33475b !important;
}

h6 {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #33475b !important;
}

.default-button {
  color: #ffffff!important;
  background: #52c3c8 !important;
}

.secondary-button {
  color: #52c3c8 !important;
  background: #ffffff !important;
  border: 1px solid #52C3C8 !important;
}

.tertiary-button {
  background: #EAF0F6 !important;
}

.company_logo {
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-block {
  display: inline-block;
}

.truncate-text {
  @include mat-truncate-line();
}

.mat-sort-header-text-center .mat-sort-header-container {
    display: flex;
    justify-content: center;
}

.mat-sort-header-arrow {
  margin: 0 !important;
}

.align-left .mat-sort-header-container {
    display: flex;
    justify-content: flex-start;
    text-align: left;
}

.color-white {
  color: #fff !important;
}

.color-facebook{color: #3b5998;}
.color-facebook:hover{color: #6c8dbe;}
.color-twitter{color: #00aced;}
.color-twitter:hover{color: #4db8ff;}
.color-linkedin{color: #007bb6;}
.color-linkedin:hover{color: #2b9dff;}
