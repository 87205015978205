.no-padding {
  padding: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.no-text-decoration {
  text-decoration: none;
}

.none {
  display: none;
}

.spacer {
  flex: 1 1 auto;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #222 !important;
}

.bold {
  font-weight: bold;
}

.overflow-auto {
  overflow: auto;
}

// .w {
//   &-25 {
//     width: 25% !important;
//   }

//   &-30 {
//     width: 30% !important;
//   }

//   &-33 {
//     width: 33% !important;
//   }

//   &-50 {
//     width: 50% !important;
//   }

//   &-75 {
//     width: 75% !important;
//   }

//   &-80 {
//     width: 80% !important;
//   }

//   &-90 {
//     width: 90% !important;
//   }

//   &-100 {
//     width: 100% !important;
//   }
// }

// @for $i from 0 through 10 {
//   $class: 5 * $i;
//   $val: #{$class}px;

//   .m-#{$class} {
//     margin: $val !important;
//   }

//   .mv-#{$class} {
//     margin: $val 0 !important;
//   }
//   .mh-#{$class} {
//     margin: 0 $val !important;
//   }

//   .mb-#{$class} {
//     margin-bottom: $val !important;
//   }
//   .mt-#{$class} {
//     margin-top: $val !important;
//   }
//   .ml-#{$class} {
//     margin-left: $val !important;
//   }
//   .mr-#{$class} {
//     margin-right: $val !important;
//   }

//   .p-#{$class} {
//     padding: $val !important;
//   }

//   .pv-#{$class} {
//     padding: $val 0 !important;
//   }
//   .ph-#{$class} {
//     padding: 0 $val !important;
//   }

//   .pb-#{$class} {
//     padding-bottom: $val !important;
//   }
//   .pt-#{$class} {
//     padding-top: $val !important;
//   }
//   .pl-#{$class} {
//     padding-left: $val !important;
//   }
//   .pr-#{$class} {
//     padding-right: $val !important;
//   }
// }

.h-100 {
  height: 100%;
}

.hide {
  display: none !important;
}