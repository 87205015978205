.rx-editor{
  overflow-y: auto;
  position: relative;
}

.rx-toolbar {
  flex: 1;
  display: flex;
  height: unset;
  flex-wrap: wrap;
  white-space: nowrap;
  scrollbar-width: none;
}

.rx-placeholder {
  padding: 0 4px 4px;
  border-radius: 4px;
}

.rx-panel, .rx-popup  {
  z-index: 1000 !important;
}
