/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "assets/theme/theme";

@import "assets/theme/material-override";
@import "assets/theme/content-hub";
@import "assets/theme/settings.content-hub";
@import "assets/theme/users-global";
@import "assets/theme/settings";
@import "assets/theme/dashboard";
@import "assets/theme/posts";
@import "assets/theme/roles-and-permissions";

// @import "node_modules/bulma/bulma";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}

.menu-tooltip {
  white-space: pre-line !important;
}

.my-panel-class {
  width: 280px;
  min-width: unset !important;
}

.dropdown-width-full {
  min-width: 100% !important;
  margin-top: 0px !important;
  max-width: 100% !important;
}

.snackbar {
  padding: 0px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 12px;
}

.mat-fab,
.mat-button,
.mat-mini-fab,
.mat-flat-button,
.mat-icon-button,
.mat-raised-button,
.mat-stroked-button {
  border-radius: 4px !important;

  /* Extra Small button */
  &.btn-xs {
    height: 28px;
    min-height: 28px;
    max-height: 28px;
    padding: 4px 14px !important;
    font-size: 12px;
  }

  /* Small button */
  &.btn-sm {
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 4px 14px !important;
    font-size: 12px;
  }

  /* Medium button */
  &.btn-md {
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 8px 12px !important;
  }
}

.mat-stroked-button.mat-primary:not(.mat-button-disabled) {
  border-color: var(--fuse-primary) !important;
}

.row-gap {
  row-gap: 1rem;
}

.col-gap {
  column-gap: 10px;
}

:ng-deep .mat-snack-bar-container {
  padding: 0px !important;
}

.iti {
  display: block !important;

  .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
  }

  .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 326px;
    max-height: 170px;
  }

  .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
  }

  .search-container input:focus {
    outline: none;
  }
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input {
  input {
    height: 50px;
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(203 213 225 / var(--tw-border-opacity));
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-radius: 6px;
    font-size: 18px;
    width: 100%;
  }
}

.invalid ngx-intl-tel-input input {
  border-color: #dc2626;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

body:not(.os-mac) ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #0000;
}

body:not(.os-mac) ::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 0 20px #0000003d;
}

body:not(.os-mac) ::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 0 20px #0000003d;
}

#hubspot-messages-iframe-container:not(:has(>.hs-shadow-container.active)) {
  height: 82px !important;
  min-height: 82px !important;
}

.rx-editor{
  overflow-y: auto;
}

.disable-submit, .disable-action {
  cursor: not-allowed !important;
  color: #94A3B8 !important;
  opacity: 0.7;
}