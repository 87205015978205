.dashboard-card-height {
  height: 420px;
}

.dashboard-card {
  padding: 2em 3em;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2)
}

.cards-padding {
  padding-top: 20px;
}

.align-baseline {
  align-items: baseline;
}

.card-title {
  background-color: #F5F8FA;
  padding: 8px;
}

.ar-container {
  max-height: 50vh;
}
