.sidebar-active-route {
  border-left: 4px solid #008ABD;
  background-color: #F5F8FA;
  font-weight: 600;
  color: #008ABD !important;
}

.sidebar-link-active {
  color: #008ABD !important;
}

.ml-auto {
  margin-left: auto;
}

.relative {
  position: relative;
  margin: auto;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.m-badge {
  background: #eaeaea;
  color: #444;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
  border-radius: 0.75rem;
}

.m-badge.m-badge-primary {
  background-color: #52C3C8;
  color: #ffffff;
}

.m-badge.m-badge-accent {
  background-color: #99ACC2;
  color: #ffffff;
}

.m-badge.m-badge-accent {
  background-color: #99ACC2;
  color: #ffffff;
}

.m-badge.m-badge-secondary {
  font-size: 0.6rem;
  padding: 0 4px;
  background-color: #008ABD;
  color: #ffffff;
}
