.container {
  position: relative;
  min-height: 200px;
}

.action-bar {
  background-color: #018abd;
  align-items: center;
  padding: 5px 20px;
  color: white;
  opacity: 0.8;
}

.border-bottom-1 {
  border-bottom: 1px solid lightgray;
}

.post-list-image {
  width: 70px;
  max-height: 59px;
  margin: 5px 0;
  vertical-align: middle
}

.border-top-2 {
  border-top: 2px solid lightgray
}

.vertical-middle {
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  text-decoration: none;
  display: block;
  color: $link-color;
}

.link-color {
  color: #008ABD;
}

.post-number {
  font-size: 28px !important;
  font-weight: bold;
}

