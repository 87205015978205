.ql-container .ql-editor {
  min-height: 90px;
  max-height: 200px
}

.quill[data-fulltoolbar="false"] .ql-formats {
  .ql-indent,
  .ql-blockquote,
  .ql-code-block,
  .ql-header[value="1"],
  .ql-header[value="2"],
  .ql-indent[value="-1"],
  .ql-indent[value="+1"],
  .ql-script,
  .ql-picker,
  .ql-link,
  .ql-image {
    display: none;
  }
}

.ql-toolbar {
  .ql-formats {
    button,
    span.ql-picker {
      position: relative;
    }

    & mat-slide-toggle:hover:after,
    & span.ql-merge-field:hover:after,
    & button:hover:after,
    & button:active:after,
    & button:focus:after,
    & span.ql-picker:hover:after,
    & span.ql-picker:active:after,
    & span.ql-picker:focus:after {
      position: absolute;
      background: black;
      color: white;
      padding: 0.5em;
      border-radius: 0.5em;
      width: max-content;
      z-index: 1;
      left: 0;
      bottom: 100%;
    }

    .ql-full-toolbar:hover:after,
    .ql-full-toolbar:active:after,
    .ql-full-toolbar:focus:after {
      content: "Toggle Full Toolbar";
    }

    .ql-merge-field:hover:after,
    .ql-merge-field:active:after,
    .ql-merge-field:focus:after {
      content: "Merge Field";
    }

    .ql-bold:hover:after,
    .ql-bold:active:after,
    .ql-bold:focus:after {
      content: "Bold";
    }

    .ql-italic:hover:after,
    .ql-italic:active:after,
    .ql-italic:focus:after {
      content: "Italic";
    }

    .ql-underline:hover:after,
    .ql-underline:active:after,
    .ql-underline:focus:after {
      content: "Underline";
    }

    .ql-strike:hover:after,
    .ql-strike:active:after,
    .ql-strike:focus:after {
      content: "Strike";
    }

    .ql-blockquote:hover:after,
    .ql-blockquote:active:after,
    .ql-blockquote:focus:after {
      content: "Blockquote";
    }

    .ql-code-block:hover:after,
    .ql-code-block:active:after,
    .ql-code-block:focus:after {
      content: "Code Block";
    }

    .ql-header[value="1"]:hover:after,
    .ql-header[value="1"]:active:after,
    .ql-header[value="1"]:focus:after {
      content: "Header 1";
    }

    .ql-header[value="2"]:hover:after,
    .ql-header[value="2"]:active:after,
    .ql-header[value="2"]:focus:after {
      content: "Header 2";
    }

    .ql-list[value="ordered"]:hover:after,
    .ql-list[value="ordered"]:active:after,
    .ql-list[value="ordered"]:focus:after {
      content: "Ordered list";
    }

    .ql-list[value="bullet"]:hover:after,
    .ql-list[value="bullet"]:active:after,
    .ql-list[value="bullet"]:focus:after {
      content: "Bullet list";
    }

    .ql-script[value="sub"]:hover:after,
    .ql-script[value="sub"]:active:after,
    .ql-script[value="sub"]:focus:after {
      content: "Sub";
    }

    .ql-indent[value="-1"]:hover:after,
    .ql-indent[value="-1"]:active:after,
    .ql-indent[value="-1"]:focus:after {
      content: "Indent -1";
    }

    .ql-indent[value="+1"]:hover:after,
    .ql-indent[value="+1"]:active:after,
    .ql-indent[value="+1"]:focus:after {
      content: "Indent +1";
    }

    .ql-header.ql-picker:hover:after,
    .ql-header.ql-picker:active:after,
    .ql-header.ql-picker:focus:after {
      content: "Header";
    }

    .ql-color.ql-picker.ql-color-picker:hover:after,
    .ql-color.ql-picker.ql-color-picker:active:after,
    .ql-color.ql-picker.ql-color-picker:focus:after {
      content: "Font Color";
    }

    .ql-background.ql-picker.ql-color-picker:hover:after,
    .ql-background.ql-picker.ql-color-picker:active:after,
    .ql-background.ql-picker.ql-color-picker:focus:after {
      content: "Font Background Color";
    }

    .ql-font.ql-picker:hover:after,
    .ql-font.ql-picker:active:after,
    .ql-font.ql-picker:focus:after {
      content: "Font Family";
    }

    .ql-align.ql-picker:hover:after,
    .ql-align.ql-picker:active:after,
    .ql-align.ql-picker:focus:after {
      content: "Alignment";
    }

    .ql-link:hover:after,
    .ql-link:active:after,
    .ql-link:focus:after {
      content: "Link";
    }

    .ql-image:hover:after,
    .ql-image:active:after,
    .ql-image:focus:after {
      content: "Image";
    }

    .ql-clean:hover:after,
    .ql-clean:active:after,
    .ql-clean:focus:after {
      content: "Clear formats";
    }
  }
}

.ql-mention-list-container {
  height: 100px;
  overflow-y: auto;
  top: 0!important;
}

.ql-mention-list {
  background-color: rgb(230, 230, 230);
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  padding: 0;
  z-index: 100;
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 10px 10px;
  text-transform: capitalize;
}

.ql-mention-list .ql-mention-list-item.selected {
  background-color: rgba(0, 0, 0, 0.12);
}

.mention {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 2px 5px;
  border-radius: 2px;
  line-height: 0;
}

.mention[data-value*="div"] {
  background-color: unset;

  .ql-mention-denotation-char {
    display: none;
  }

  a {
    line-height: 12px !important;
  }
}
