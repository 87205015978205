.user-actions {
  display: flex;
  justify-content: space-between;
}

.layout-margin {
  margin: 2em;
}

.container {
  position: relative;
  min-height: 200px;
}

tr.example-detail-row {
  height: 0;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef;
}

.flex {
  display: flex;
  // flex-wrap: wrap;

  &.align-items-center {
    align-items: center;
  }

  // &.margin-align {
  //   margin: 0 -15px;
  // }
}

// ADD USER MODAL
section.user-information {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}

.user-form mat-form-field {
  margin-top: 10px;
}

// USERGROUPS
.user-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.row mat-card {
  width: 48%;
}

.usergroup-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dedede;
  padding: 10px;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder, .cdk-drag {
  opacity: 0.5;
}


