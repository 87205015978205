.img-background-color {
  background-color: #52c3c8;
}

.custom-image-border {
  border: 1px solid lightgray;
}

.form-field-margin {
  margin-bottom: -1.25em;
}
