/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */

@tailwind utilities;

.aspect-media {
  @apply aspect-[16/9];
}

.icon-size-2 {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;

  & svg {
    width: 8px !important;
    height: 8px !important;
  }
}

.pill, .pill-icon {
  &:not(.pill-icon) {
    @apply inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase;
  }
  &:not(.pill) {
    @apply inline-flex justify-center items-center font-bold text-xs p-1 rounded-full tracking-wide uppercase;
  }

  &.pill-primary {
    @apply text-primary-600 bg-primary-100;

    &.pill-border {
      @apply border border-primary-600;
    }
  }

  &.pill-success {
    color: #15803D;
    background-color: #F0FDF4;

    &.pill-border {
      border-width: 1px;
      border-color: #4ADE80;
    }
  }

  &.pill-failed {
    color: #B91C1C;
    background-color: #FEF2F2;

    &.pill-border {
      border-width: 1px;
      border-color: #F87171;
    }
  }

  &.pill-pending {
    color: #B45309 !important;
    background-color: #FFFBEB;

    &.pill-border {
      border-width: 1px;
      border-color: #FBBF24;
    }
  }

  &.pill-slate {
    @apply text-slate-600 bg-slate-100;

    &.pill-border {
      @apply border border-slate-600;
    }
  }

  &.pill-gray {
    @apply text-gray-600 bg-gray-200;

    &.pill-border {
      @apply border border-gray-600;
    }
  }

  &.pill-zinc {
    @apply text-zinc-600 bg-zinc-200;

    &.pill-border {
      @apply border border-zinc-600;
    }
  }

  &.pill-neutral {
    @apply text-neutral-600 bg-neutral-200;

    &.pill-border {
      @apply border border-neutral-600;
    }
  }

  &.pill-stone {
    @apply text-stone-600 bg-stone-200;

    &.pill-border {
      @apply border border-stone-600;
    }
  }

  &.pill-red {
    @apply text-red-600 bg-red-100;

    &.pill-border {
      @apply border border-red-600;
    }
  }

  &.pill-orange {
    @apply text-orange-600 bg-orange-100;

    &.pill-border {
      @apply border border-orange-600;
    }
  }

  &.pill-amber {
    @apply text-amber-600 bg-amber-100;

    &.pill-border {
      @apply border border-amber-600;
    }
  }

  &.pill-yellow {
    @apply text-yellow-600 bg-yellow-100;

    &.pill-border {
      @apply border border-yellow-600;
    }
  }

  &.pill-lime {
    @apply text-lime-600 bg-lime-100;

    &.pill-border {
      @apply border border-lime-600;
    }
  }

  &.pill-green {
    @apply text-green-600 bg-green-100;

    &.pill-border {
      @apply border border-green-600;
    }
  }

  &.pill-emerald {
    @apply text-emerald-600 bg-emerald-100;

    &.pill-border {
      @apply border border-emerald-600;
    }
  }

  &.pill-teal {
    @apply text-teal-600 bg-teal-100;

    &.pill-border {
      @apply border border-teal-600;
    }
  }

  &.pill-cyan {
    @apply text-cyan-600 bg-cyan-100;

    &.pill-border {
      @apply border border-cyan-600;
    }
  }

  &.pill-sky {
    @apply text-sky-600 bg-sky-100;

    &.pill-border {
      @apply border border-sky-600;
    }
  }

  &.pill-blue {
    @apply text-blue-600 bg-blue-100;

    &.pill-border {
      @apply border border-blue-600;
    }
  }

  &.pill-indigo {
    @apply text-indigo-600 bg-indigo-100;

    &.pill-border {
      @apply border border-indigo-600;
    }
  }

  &.pill-purple {
    @apply text-purple-600 bg-purple-100;

    &.pill-border {
      @apply border border-purple-600;
    }
  }

  &.pill-fuchsia {
    @apply text-fuchsia-600 bg-fuchsia-100;

    &.pill-border {
      @apply border border-fuchsia-600;
    }
  }

  &.pill-pink {
    @apply text-pink-600 bg-pink-100;

    &.pill-border {
      @apply border border-pink-600;
    }
  }

  &.pill-rose {
    @apply text-rose-600 bg-rose-100;

    &.pill-border {
      @apply border border-rose-600;
    }
  }
}
