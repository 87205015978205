.source-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & mat-card {
    height: 200px;
    width: 350px;
    margin-right: 16px;
    margin-bottom: 20px;
  }

  & mat-card-title {
    display: flex;
  }

  & mat-icon {
    cursor:pointer;
  }
}

.source-info {
  display: flex;
  position: absolute;
  bottom: 10px;
  width: 90%;
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-modal {
  justify-content: center;

  & mat-dialog-actions{
    justify-content: flex-end;
  }

  & mat-dialog-actions mat-icon{
    cursor: pointer;
  }
}

.network-list {
  margin-bottom: 50px;
}

.hover-color {
  background: #4ab0b4;
  color: #ffffff;
}

.text-end {
  text-align: end;
}

.width-45 {
  width: 45%;
}

.width-100 {
  width: 100%;
}

.margin {
  margin: 20px 0;
}
.margin-right{
  margin: 5px;
}

.margin-top {
  margin-top: 20px;
}


.row {
  margin-top: 20px;
}

.pressed-color {
  background: #a9e1e4;
  color: #ffffff;
}

.modal-body button {
  border: 1px solid #eee;
  border-radius: 0;
  outline: none;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
  padding: 10px 0;
}

.text-break {
  word-break: break-all;
}
